// src/context/LgpdContext.tsx
import React, { createContext, useContext, useState } from 'react'

interface LgpdContextProps {
  lgpdChecked: boolean;
  setLgpdChecked: (checked: boolean) => void;
  cpfSearch: string;
  setCpfSearch: (cpf: string) => void;
}

const LgpdContext = createContext<LgpdContextProps | undefined>(undefined);

export const LgpdProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [lgpdChecked, setLgpdChecked] = useState(false);
  const [cpfSearch, setCpfSearch] = useState("");

  return (
    <LgpdContext.Provider value={{ lgpdChecked, setLgpdChecked, cpfSearch, setCpfSearch }}>
      {children}
    </LgpdContext.Provider>
  );
};

export const useContextSystem = () => {
  const context = useContext(LgpdContext);
  if (!context) {
    throw new Error('useContextSystem must be used within a LgpdProvider');
  }
  return context;
};
